
import { defineComponent, toRefs } from "vue";
export default defineComponent({
  emits: ["update:secret", "update:signExternally"],
  props: {
    secret: {
      type: String,
      default: ""
    },
    manualHint: {
      type: String,
      default:
        "Check FAQ for valid secret types. Your secret is NOT saved NOR sent anywhere. It's only used to sign transactions locally."
    },
    externalHint: {
      type: String,
      default: ""
    },
    signExternally: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { signExternally } = toRefs(props);
    const onToggleSignExternally = () => {
      emit("update:signExternally", !signExternally.value);
    };
    return { onToggleSignExternally };
  }
});
